import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'SearchBox',
})
export default class SearchBox extends Vue {
  @Prop()
  public placeholder!: string;

  public search: string = '';

  clearSearch() {
    this.search = '';
    this.$emit('clear');
  }

  triggerSearch() {
    this.$emit('search', this.search);
  }
}
